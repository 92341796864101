<div class="flex flex-col items-center justify-center w-full container-h bg-landing">
    <div class="home-container flex flex-col items-center justify-center">
        <div>
            <div class="text-4xl font-bold mb-4 t-w">DTC Removal</div>
        </div>
        <div>
            <div class="mb-8 t-w text-2xl">Introduce the file and the errors to be removed. We will take care of the rest
            </div>
        </div>
        <div class="flex justify-center text-2xl font-bold t-w" *ngIf="dataName && dataName.brand">
            {{dataName.brand}} {{ dataName.ecuProducer}} {{ dataName.ecuType}}
        </div>
        <div class="flex justify-center p-6 w-full">
            <form class="w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-2xl" [formGroup]="form" *ngIf="!filePassed">
                <div class="flex flex-col">
                    <div class="custom-file-input">
                        <input #fileInput type="file" hidden (change)="onFileSelected($event)">
                        <button mat-raised-button (click)="triggerFileInput()">
                            <mat-icon>attach_file</mat-icon>
                            Choose File
                        </button>
                        <span class="t-w" *ngIf="fileName">{{ fileName }}</span>
                    </div>
                </div>
                <div class="flex items-center justify-center mt-4">
                    <button
                        class="ml-4 text-2xl btn-landing"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="save()"
                        [disabled]="!form.valid || isLoadingSpinnerActive">
                        <mat-spinner *ngIf="isLoadingSpinnerActive"></mat-spinner>
                        Submit
                    </button>
                </div>
            </form>
            <form [ngClass]="{'max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-2xl' : !isAdmin}" class="w-full"
                  [formGroup]="formErrors"
                  *ngIf="filePassed">
                <div class="flex flex-col">
                    <mat-form-field
                        class="flex-auto gt-xs:pr-3 w-full">
                        <mat-label class="t-w">Errors (click ENTER to add new error)</mat-label>
                        <mat-chip-grid #chipGrid aria-label="Enter errors">
                            <mat-chip-row *ngFor="let error of errors"
                                          (click)="onErrorClick(error)"
                                          (removed)="removeError(error)"
                                          class="chip-row">
                                {{ error }}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip-row>
                            <input
                                appUppercase
                                placeholder="New error..."
                                [matChipInputFor]="chipGrid"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="addError($event)"
                            />
                        </mat-chip-grid>

                    </mat-form-field>
                </div>
                <div *ngIf="isAdmin">
                    <div *ngFor="let map of signatureData.maps;" class="map-container">
                        <h3 class="map-type-header p-2">{{getMapTypeName(map.mapType)}}</h3>
                        <div class="hex-scroll-container" #mapContainer [attr.data-map-type]="map.mapType" (scroll)="syncScroll($event)">
                            <div class="hex-values">
                                <div class="hex-item" *ngFor="let hexValue of map.hexValues; let i = index">
                                    <div [ngClass]="{'hex-selected': hexValue.isSelect, 'hex-scrolled': hexValue.isScrolled}" class="hex-container">
                                        <div class="index">{{ i + 1 }}</div>
                                        <div class="hex-value">{{ hexValue.value }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center mt-4">
                    <button
                        class="ml-4 text-2xl btn-landing"
                        mat-flat-button
                        type="button"
                        [color]="'primary'"
                        (click)="save()"
                        [disabled]="!form.valid || isLoadingSpinnerActive">
                        <mat-spinner *ngIf="isLoadingSpinnerActive"></mat-spinner>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
